.main {
  @include responsive_desktop-only {
    margin-bottom: 0;
  }

  &:before {
    transition: opacity $easing_timing-base 1s;
    opacity: 0;
    content: ' ';
  }

  &:after {
    transition: opacity $easing_timing-base 1s;
    opacity: 0;
    content: ' ';
  }
}

.main--full {
  min-height: 100%;
  overflow: hidden;
}
