.footer {  
  background-color: $color_gray-light;
  width: 100%;
  font-size: 14px;

}

.footer__container {
  display: flex;
  gap: $spacing_horizontal-large;
  max-width: $spacing_container-maxWidth;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $spacing_vertical-medium;
  padding-left: $spacing_horizontal-medium;
  padding-right: $spacing_horizontal-medium;
  padding-top: $spacing_horizontal-medium;
  padding-bottom: $spacing_horizontal-medium;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive_mobile {
    flex-direction: column;
  }
}

.footer__column {
  flex: 1;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $color_primary-base;
    }
  }

  .privacy-link {
    font-size: 14px;
  }
}

.footer__brand-logo {
  height: 70px;
  margin-bottom: $spacing_vertical-base;
  @media only screen and (max-width:768px) {
    height:60px;
  }
}
