$title_color-default: $color_black;

.title {
  @include heading;

  font-family: $font_family-accent;
  color: $title_color-default;
  font-weight: $font_weight-medium;
  font-size: $font_size-large;
  line-height: 1.125;
  letter-spacing: 0;

  & + .subtitle {
    margin-top: -$spacing_vertical-base;
  }

  @include responsive_mobile {
    font-size: $font_size-medium-3;
  }
  @include responsive_tablet-only {
    font-size: 1.4rem;
  }
}
.title-process {
  font-weight: 600;
  margin-bottom: 15px;
}
.form-title {
  color: #b84446;  
}

.text-bold-upper {
  font-weight: bold;
  text-transform: uppercase;
}

@import 'mod-text-align';
@import 'mod-color';
@import 'mod-size';
