$control_radius: $radius_base;
$control_radius-small: $radius_small;

$control_border-width: 2px;

$control_padding-vertical: $spacing_vertical-small;
$control_padding-horizontal: $spacing_horizontal-medium;

@mixin control {
  display: inline-flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: $control_padding-vertical $control_padding-horizontal;
  border: $control_border-width solid transparent;
  border-radius: $control_radius;
  font-size: $font_size-base;
  vertical-align: top;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus,
  &:active {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

@mixin control-small {
  border-radius: $control_radius-small;
  font-size: $font_size-small;

  &:after {
    font-size: $font_size-small;
  }
}

@mixin control-medium {
  font-size: $font_size-medium;

  &:after {
    font-size: $font_size-medium;
  }
}

@mixin control-large {
  font-size: $font_size-large;

  &:after {
    font-size: $font_size-large;
  }
}
