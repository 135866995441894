.section--hero {
  position: relative;
  display: flex;
  min-height: 850px;
  padding-bottom: $spacing_vertical-medium;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include responsive_mobile {
    padding-bottom: initial;
  }

  .section__container {
    flex: 1;
  }

  .row {
    align-items: center;
  }
}
