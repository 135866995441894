$dropdown_tick-dimension: 4px;

.navbar__menu {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
  background-color: inherit;
  transition: background-color $easing_timing-fast ease-in-out;

  @include responsive_mobile {
    border-top: 1px solid $color_gray-light;
    display: block;
    height: calc(100vh - #{$navbar_height-mobile});
    padding-bottom: $spacing_vertical-large;
    overflow: hidden;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: $navbar_height-mobile;
    left: -$spacing_horizontal-medium;
    width: calc(100% + (2 * #{$spacing_horizontal-medium}));
    border-bottom-right-radius: $radius_base;
    border-bottom-left-radius: $radius_base;
    background-color: $navbar_background-color;
    transition: box-shadow $easing_timing-fast ease-in-out, background-color $easing_timing-fast ease-in-out,
                opacity $easing_timing-base $easing_function-base,
                visibility 0s $easing_timing-base;

    &.navbar__menu--active {
      opacity: 1;
      visibility: visible;
      transition: box-shadow $easing_timing-fast ease-in-out, background-color $easing_timing-fast ease-in-out,
                  opacity $easing_timing-base $easing_function-base;
    }

    .navbar__start, .navbar__end {
      display: block;
      width: 100%;
      text-align: center;
    }
  
    .navbar__item, .menu_list-item {
      text-align: left;
    }

    .menu {
      width: 100%;
      padding: 0;
    }

    .menu__label + .menu__list {
      display: none;
      overflow: scroll;
      padding-bottom: $spacing_vertical-large-2;
      opacity: 0;
      backface-visibility: hidden;
      transform: translateX(100%);
    }

    .link, .menu__link, .menu__label {
      @include button_reset;

      width: 100%;
      color: $color_primary-base;
      padding: $spacing_vertical-small-2 $spacing_horizontal-medium;
      border-bottom: 1px solid $color_gray-light;
      font-weight: $font_weight-normal;
    }
    .link, .menu__link {
      height: 100%;
    }
    .menu__link, .menu__label {
      font-size: $font_size-base;
      line-height: 1.9;
      padding-left: $spacing_horizontal-medium-2;
    }
    .link {
      font-weight: $font_weight-semibold;
      font-size: $font_size-medium-2;
    }
    .link--back {
      font-size: $font_size-base;
      line-height: 1.9;
      color: $color_gray-dark;
    
      &:before {
        display: inline-block;
        content: '';
        height: $dropdown_tick-dimension;
        width: $dropdown_tick-dimension;
        border-radius: 1px;
        border-top: 2px solid $color_gray-dark;
        border-left: 2px solid $color_gray-dark;
        transition: transform $easing_timing-fast $easing_function-base;
        transform: rotate(315deg);
      }
    }
    .menu__label {
      cursor: pointer;
      
      &:before {
        content: '';
        height: $dropdown_tick-dimension;
        width: $dropdown_tick-dimension;
        position: absolute;
        border-radius: 1px;
        border-top: 2px solid $color_gray-dark;
        border-left: 2px solid $color_gray-dark;
        transition: transform $easing_timing-fast $easing_function-base;
        transform: rotate(135deg) translateY(-100%);
        right: $spacing_horizontal-medium;
      }
    }

    .menu--expanded {
      z-index: 10;
      background: $color_white;
      display: flex;
      flex-direction: column;
      backface-visibility: hidden;

    
      @keyframes slide {
          100% { opacity: 1; transform: translateX(0); }
      }
      
      .menu__list {
        opacity: 1;
        // max-height: initial;
        display: block;
        animation: slide 0.5s forwards;
      }

      .menu__label {
        position: relative;
        font-weight: $font_weight-semibold;
        font-size: $font_size-medium-2;
        background-color: $navbar_background-color;
        padding-left: 0;
        padding-right: 0;

        &:before {
          display: none;
        }

        a {
          display: flex;
          flex-wrap: wrap;
          padding-left: $spacing_horizontal-medium;
          padding-right: $spacing_horizontal-medium;
        }
      }

      .a-button--expanded-only {
        display: block;
        font-size: $font_size-small-3;
        padding: $spacing_horizontal-small;
        margin-left: auto;
        box-shadow: none;
      }
    }

    
    .dropdown {
      display: block;
      position: static;
    }
    .dropdown__trigger {
      display: flex;
      flex-wrap: wrap;
      
      a:before {
        transform: rotate(135deg) !important;
        top: calc(50% - 5px);
        right: $spacing_horizontal-medium;
      }
    }
    .dropdown__menu {
      width: 100%;
      top: 0;
      height: 100%;
      padding-top: 0;
    }
    .dropdown__content {
      display: block;
      position: relative;
      padding: 0;
      width: 100%;
      height: 100%;
      box-shadow: none;
      border-top: none;
    }
  }
}