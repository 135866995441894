.subtitle--size-5 {
  font-size: $font_size-medium-2;
}

.subtitle--size-4 {
  font-size: $font_size-large;
}

.subtitle--size-3 {
  font-size: $font_size-large-2;
}

.subtitle--size-2 {
  font-size: $font_size-large-3;
}

.subtitle--size-1 {
  font-size: $font_size-huge;
}
