$navbar_burger-span-height: 2px;
$navbar_burger-span-width: 22px;
$navbar_burger-span-distance: 9px;

.navbar__burger {
  display: none;
  position: relative;
  width: calc(#{$navbar_burger-span-width});
  height: 100%;
  margin-left: auto;
  cursor: pointer;

  @include responsive_mobile {
    display: block;
  }

  span {
    display: block;
    position: absolute;
    left: calc(50% - #{$navbar_burger-span-width} / 2);
    width: $navbar_burger-span-width;
    height: $navbar_burger-span-height;
    background-color: $color_primary-base;
    transition-property: background-color, opacity, transform;
    transition-duration: $easing_timing-base;
    transition-timing-function: $easing_function-base;
    transform-origin: center;

    &:nth-child(1) {
      top: calc(50% - 1px - #{$navbar_burger-span-distance});
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
    }

    &:nth-child(3) {
      top: calc(50% - 1px + #{$navbar_burger-span-distance});
    }
  }
}

.navbar__burger--active {
  span {
    &:nth-child(1) {
      transform: translateY($navbar_burger-span-distance) rotate(45deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translateY(-$navbar_burger-span-distance) rotate(-45deg);
    }
  }
}
