.home__gallery{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    flex: 1 1 50%;
    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }
     img:hover {
    transition: transform 200ms ease-in-out;
    transform: scale(1.05);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  }
  @media screen and (max-width: 440px) {
    flex-wrap: wrap;
    flex: 1 1 50%;
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
  }
  img:hover {
    transition: transform 200ms ease-in-out;
    transform: scale(1.05);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

}
.preview-list{
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 1000px;
  margin: 100px 0;
  gap: 15px;
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
    transition: 100ms ease-in-out;
    @include responsive_mobile-only {
      width: 100px;
      height: 100px;
    }
  }
  img:hover {
    transition: transform 200ms ease-in-out;
    transform: scale(1.05);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}