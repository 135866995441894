.navbar__end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  text-align: center;
  span {
    @include responsive_mobile-only {
      font-size: 12px;
    }
  }
  .fa-regular {
    font-size: 20px;
    cursor: pointer;
    margin-left: 25px;
  }
  .mobile-number {
    font-size: 20px;
    @include responsive_tablet-only {
      display: none;
    }
    @include responsive_mobile-only {
      display: none;
    }
  }
}