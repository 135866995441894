$color_white: #ffffff;
$color_black: #0A0000;
$color_black-2: #020000;

$color_gray-base: #F7F7F7;
$color_gray-light: #F8F7F7;
$color_gray-dark: #919798;

$color_text-link-base: #100000;



// TODO: go through below and remove what isn't being used
$color_gray-light-2: #f4f5f7;

$color_gray-dark-2: #eceff3;

$color_primary-base: #B84446;
$color_primary-light: lighten(#1b3452, 15%);
$color_primary-invert: #ffffff;

$color_secondary-base: #2091d0;
$color_secondary-invert: #ffffff;

$color_tertiary: #ffbf3c;

$color_danger-base: #fd4842;

$color_bg-transparent: hsla(0,0%,98%,.64);

$color_text-body-base: #1b3452;
$color_text-body-primary: #1b3452;
$color_text-body-light: #ffffff;
$color_text-heading-base: #eceff3;

$color_text-link-hover: #1b3452;
$color_text-link-invert: #7a97ab;