.paragraph {
  @include spacing_vertical-small;

  color: $color_black-2;
  font-size: $font_size-base;
  line-height: 16px/32px;
  // white-space: pre-line;
  text-align: left;
}

.paragraph--center-container {
  max-width: $spacing_inner-container-maxWidth;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $spacing_vertical-large;  
}
.content-paragraph-color {
  color:grey;
}
.financing-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  font-weight: bold;
  font-size: 1.5rem;
  @include responsive_mobile_only {
    font-size: 1.0rem;
  }
  .financing-option-item{
    display: flex;
    // justify-content: center;
    // background-color:#B84446;
    padding:10px 20px;
    color:#000000;
    width: 55%;
    font-weight: 600;
    margin-bottom: 10px;
    border-radius: 30px;
    justify-content: flex-start;
    gap: 10px;
    .check-icon{
      height: 36px;
      width: 36px;
      background-color: #B84446;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      border-radius: 50%;
    }
    @include responsive_mobile-only {
      width: 75%;
    }
  }
}
.financing-step-title-wrapper{
  position: relative;
  display: flex;  
  justify-content: center;
  align-items: center;   
  .financing-step-number{
    width: 120px;
  }
  .financing-step-title {
    width: 300px;
  }
}
.phone-number {
  font-size:20px;
  font-weight: 600;
  cursor: pointer;
}
.contact-email {
  cursor: pointer;
}

@import 'mod-text-align';
@import 'mod-size';
