$button_color-primary: $color_primary-base;
$button_color-primary-invert: $color_primary-invert;
$button_color-secondary: $color_secondary-base;
$button_color-secondary-invert: $color_secondary-invert;

$button_border-width: 2px;
$button_focus-box-shadow-size: 0 0 0 0.125em;

$button_padding-horizontal: $control_padding-horizontal;

.a-button {
  @include control;
  @include unselectable;

  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.56px;
  line-height: 1.5em;
  max-width: 100%;
  padding-right: $button_padding-horizontal;
  padding-left: $button_padding-horizontal;
  border-width: $button_border-width;
  border-color: transparent;
  border-radius: 0;
  color: $button_color-primary-invert;
  font-size: $font_size-base;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: $button_color-primary;
  transform: scale(1);
  transition: transform $easing_timing-fast $easing_function-base;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.02);
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.a-button--expanded-only {
  display: none;
}

.float-btn {
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  bottom: 30px;
  right: 30px;
  background-color: #B84446;
  color: #ffffff;
  border-radius: 50px;
  justify-content: center;
  text-align: center;
  box-shadow: 2px 2px 8px 1px #969696;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.free-quote-btn {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: end;

  @include responsive_mobile {
    left: 0;
  }

  a {
    text-decoration: none;
    color: #fff;
    background: rgba(188, 52, 52, 0.8);
    border-radius: 5px;
    padding: 1rem;
    width: 20rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

    @include responsive_mobile {
      padding: 0.75rem;
      width: 100%;
    }
  }
}
.header-free-quote-btn {
  width: fit-content;
  height: fit-content;
  // background-color: #B84446;
  color:white;
  // @include responsive_mobile {
  //   left: 0;
  // }

  a {
    text-decoration: none;
    color: #fff;
    padding:10px 20px;
    background: rgba(188, 52, 52);
    border-radius: 5px;
    // padding: 1rem;
    width: fit-content;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

    @include responsive_mobile {
      padding:10px 10px;
      font-size: .8rem;
      width: 100%;
    }
}
}

@import "mod/fullwidth";
@import "mod/size";
@import "mod/text";
