.icon--size-small {
  width: $icon_dimensions-small;
  height: $icon_dimensions-small;
}
.icon--size-medium {
  width: $icon_dimensions-medium;
  height: $icon_dimensions-medium;
}
.icon--size-large {
  width: $icon_dimensions-large;
  height: $icon_dimensions-large;
}
