.box {
  @include spacing_vertical-medium;

  position: relative;
  padding: $spacing_vertical-medium $spacing_horizontal-large;
  border: 1px solid $color_gray-dark;
  border-radius: $radius_large;
  color: $color_text-body-base;
  background-color: $color_bg-transparent;
  transition: transform $easing_timing-base $easing_function-base;
  height: 100%;

  @include responsive_mobile {
    padding: $spacing_vertical-base $spacing_horizontal-medium;
  }
}

.box--gray {
  background-color: $color_gray-base;
  border: none;
  border-radius: 0;
  text-align: center;
}

.iframe-section {
  @include responsive_mobile-only {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.box--with-iframe {
  background: white;
  padding-left: 0;
  padding-right: 0;
  width: 500px;

  @media (max-width : 550px) {
    width: 100%;
  }

  .title {
    margin: 0 27px 20px 27px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem !important;
  }

  .iframe-wrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;

    #form-iframe {
      height: 600px;
      width: 100%;
      margin-left: 10px;
      margin-top: -80px;
      margin-bottom: -70px;
    }
  }
  .iframe-wrapper-campaign{
    height:600px;
    @media screen and (max-width:850px) {
      height: 450px;
    }
    #form-iframe-campaign {
      height: 700px;
      @media screen and (max-width:850px) {
        height: 600px;
      }
      width: 100%;
      margin-left: 0px;
      margin-top: -80px;
      margin-bottom: -70px;
    }
  }
}

// .about-us {
//   display: flex;
//   flex-direction: column;
// }
.about-img {
  display: flex !important;
  width: 100%;
  align-items: center;
  img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

.box--with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: transparent;
  border: none;
  padding-left: 70px;
}
.justify-start {
  justify-content: unset !important;
}
.box__icon {
  position: absolute;
  left: 0;
  height: 48px;
  width: 48px;
  background-color: #B84446;
  color: $color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font_weight-semibold;
  border-radius: $radius_circular;
}
.box__icon__kitchen_remodel {
  background-color: rgb(12, 105, 190);
}
.box__icon_no_abs {
  height: 48px;
  width: 48px;
  background-color: $color_primary-base;
  color: $color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font_weight-semibold;
  border-radius: $radius_circular;
}
.contact-info {
  position: relative;
  display: flex;
  align-items: center;
}
.contact__icon {
  height: 37px;
  width: 37px;
  background-color: $color_primary-base;
  color: $color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font_weight-semibold;
  border-radius: $radius_circular;
}

.banner-txt-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  // background-color: rgba(188, 52, 52, 1);
  background-color: white;
  top: 40px;  
  left: 40px;
  width: fit-content;
  height: fit-content;
  @media screen and (max-width:600px) {
    height: 80px;
  }
  // background-color: rgba(132, 126, 126, 0.5);
  padding: 20px 30px;
  text-align: left;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  .d-flex{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-column{
    flex-direction: column;
  }
  .banner-mega-txt{
    font-size: 8.5rem;
    font-weight: bolder;
    color: #0c69be;
    @include responsive_mobile-only {
      font-size: 1.2rem;
    }
    @include responsive_tablet-only {
      font-size: 1.2rem;
    }
  }
  .banner-txt {
    font-size: 2rem;
    color: rgba(188, 52, 52, 1);
    // font-weight: bold;
      @include responsive_mobile-only {
        font-size: 1.2rem;
      }
    @include responsive_tablet-only {
      font-size: 1.2rem;
    }
  }

  @include responsive_tablet-only {
    height: fit-content;
  }

  @include responsive_mobile {
    height: 5rem;
    text-align: center;
  }
}

.promo-ads-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(188, 52, 52, 1);
  padding: 20px;
  text-align: right;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  .promo-ad {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    @include responsive_mobile {
      font-size: 1rem;
    }
    @include responsive_tablet-only {
      font-size: 1.2rem;
    }
  }
  @include responsive_tablet-only {
    padding: 10px;
  }
}

.promo-ads-wrapper-campaign {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 50%;
  @media screen and (max-width:1000px) {
    width: 90%;
  }
  height: fit-content;
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
  padding: 40px;
  text-align: right;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  @media screen and (min-width:300px) and (max-width:413px) {
    bottom:20px;
    padding:10px;
  }
  @media screen and (min-width:414px) and (max-width:600px) {
    bottom:20px;
  }
  @media screen and (min-width:601px) and (max-width:1023px) {
    bottom:20px;
  }
  .promo-ad {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    @include responsive_mobile {
      font-size: 1rem;
    }
    @include responsive_tablet-only {
      font-size: 1.2rem;
    }
  }
  @include responsive_tablet-only {
    padding: 10px;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.iframe-form-top {
  margin-top: 0.2rem !important;
  .form-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    @media (max-width : 550px) {
      width: 100%;
    }
  }
  @include responsive_tablet-only {
    margin-top: 0.4rem !important;
  }
}
