.row {
  @include spacing_vertical-large;

  position: relative;
  display: flex;
  margin-top: -$spacing_horizontal-base;

  @include responsive_desktop-only {
    margin-right: -$spacing_horizontal-base;
    margin-left: -$spacing_horizontal-base;
  }

  @include responsive_mobile {
    flex-wrap: wrap;
    margin-bottom: $spacing_vertical-medium;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@import 'mod-align';

@import 'ele-column';
@import 'ele-floating-image';
