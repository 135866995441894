.link {
  @include button_reset;

  text-align: left;
  display: inline-block;
  max-width: 100%;
  color: $color_text-link-base;
  font-size: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: color $easing_timing-base $easing_function-base;

  &:hover {
    color: $color_primary-base;

    .link__icon {
      filter: brightness(60%);
      transform: translateX($spacing_horizontal-small);
    }
    .link__icon--left {
      transform: translateX(-$spacing_horizontal-small) scaleX(-1);
    }
    .link__icon--li {
      filter: brightness(60%);
      transform: none;
    }
  }
}