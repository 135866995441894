.subtitle--text-align-center {
  text-align: center;

  @include responsive_mobile {
    text-align: initial;
  }
}
.custom--text-align-center {
  
  text-align: center;
  @include responsive_mobile {
    font-size: 20px;
    text-align: center;
  }
}
.privacy-subtitle {
  margin-bottom: 15px !important;
}