.checkbox {
  display: inline-block;
  position: relative;
  line-height: 1.25;
  cursor: pointer;

  &:hover {
  }
  &[disabled] {
    cursor: not-allowed;
  }

  .label {
    display: inline-block;
    vertical-align: text-top;
    margin-left: $spacing_horizontal-base;
  }
}

@import 'ele-item';
