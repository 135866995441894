.kitchen-remodel-logo {
    height:70px !important;
}
.kitchen-remodel-page-top-banner {
    display: flex;
    justify-content: space-around !important;
    // align-items: center;
    flex:unset !important;
    flex-wrap: wrap;    
    height: fit-content !important;
    overflow: unset !important;
}
.kitchen-remodel-bottom-ads {
    width:100%;    
    height: fit-content;
    padding:10px 20px;
    .promo-ad {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width:500px) {                
        bottom:0; 
    }
    @media screen and (max-width:501px) {
        top:52%;       
  
    }    
}
.mobile-form-btn {
    position: absolute;    
    top:60%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: fit-content;
    padding:10px;
    background-color:#0c69be;
    color: white;
    font-weight: bold;
    @media screen and (min-width:501px) {
        display: none;
    }    
}
.mobile-call-link {
    position: absolute;
    top:67%;  
    width: 75%;  
    color: white;
    text-decoration: none !important;
    .mobile-call-btn {
        display: flex;
        justify-content: center;            
        height: fit-content;
        padding: 10px;
        background-color: #0c69be;
        font-weight: bold;
    }
    @media screen and (min-width:501px) {
        display: none;
    }       
}
#apexchat_bar_invitation_wrapper, #apexchat_prechat_invitation_wrapper {
    margin-bottom: 40px !important;
    @media screen and (max-width:767px) {
        display: none !important;
        visibility: hidden !important;
    }
}
.banner-content {
    flex: 1 1 400px;
    background: white;
    margin-bottom: 80px;
    height: 400px;
    max-width: 500px;
    padding:25px;
    margin-right: 40px;
    @media screen and (max-width:965px) {
        margin-right: unset;
        margin-bottom: 30px;
    }
    @media screen and (max-width:500px) {
        position: absolute;
        top:30px;
        width:95%;
    }
    @media screen and (max-width:330px) {
        padding:15px;
    }   
}
.banner-content-inner-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    padding:20px;
    border: 2px solid rgb(12, 105, 190);  
    color: rgb(12, 105, 190)  
}
.banner-content-container {
    display: flex;
    flex-wrap: wrap;    
    flex: 1 1 55%;    
    height: fit-content;
    align-items: center;
    .banner-content-number {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 80px;
        font-size: 4.2rem;
        font-weight: bold;
        height: fit-content;
        margin-right: 10px;   
        @media screen and (max-width:330px) {
            font-size:3.6rem;
        }     
    }
    .banner-content-title {
        display: flex;
        flex-direction: column;               
        flex: 1 1 60%;        
        font-weight: bold;
        height: fit-content;        
    }
    .banner-content-title div:nth-child(1) {
        font-size: 3rem;
        @media screen and (max-width:400px) {
            font-size:2rem;
        }
    }
    .banner-content-title div:nth-child(2) {
        font-size:1.2rem;
        @media screen and (max-width:400px) {
            font-size:1rem;
        }
    }

}
.banner-content-bottom-text {
    // position: absolute;
    // bottom:0;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top:10px;
    @media screen and (max-width:400px) {
        font-size:.9rem;
    }
}

.iframe-top-wrapper {
    max-width: 500px;
    flex: 1 1 500px;
    margin-bottom: 80px;    
    @media screen and (max-width:500px) {
        display: none;
    }
}
.monday-com-responsive-box {
    position: relative;
    background-color: white;
    width: 100%;
    max-width: 500px;
    height: 650px;
    border-radius: 10px;
    padding:42px;
    overflow: hidden;
    #form-iframe {
        width:100%;
        height: 100%;    
    }
    .desktop-call-link {
        position: absolute;
        bottom: 10px;
        width: 80%;
        background-color: #0c69be;
        color: white;
        font-weight: bold;
        text-decoration: none;
        .desktop-call-btn {
            display: flex;
            justify-content: center;            
            height: fit-content;
            padding: 10px;
            background-color: #0c69be;
            font-weight: bold;
        }
    }
}
.iframe-title {
    position: absolute;
    top:30px;
    width: 80%;
    z-index: 10;
    padding: 10px 0;
    background-color: white;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(12, 105, 190);
    @media screen and (max-width:470px) {
        font-size:1.2rem;
    }
}
.iframe-wrapper-kitchen-remodel{
    position: absolute;
    top:70px;
    left:0px;
    height: 85%;
    width: 100%;
    @media screen and (max-width:470px) {
    left:-10px;
     width:105%;
    }
}


.mobile-form-popup {
    position: absolute;
    top:30px;
    width: 95%;
    height: fit-content;
    z-index: 28;
    height: fit-content;
    background-color: white;
    margin-bottom: 25px;
    border-radius: 15px;
    &-position-wrapper {
        position: relative;
        .mobile-form-close {
            position: absolute;
            top:-10px;
            right: -10px;
            color: #0c69be;
            font-size: 32px;
            z-index: 28;            
        }
        .mobile-form-title {
            position: absolute;
            z-index: 27;          
            width: 100%;
            height: fit-content;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-content: center;
            background-color: white;
            font-size: 18px;
            font-weight: bold;
        }
    }
    #form-iframe {   
        width: 100%;
        height: 650px;  
        margin-bottom: 20px;
    }
}