.row__floating-image {
    order: 1;
    padding-left: $spacing_horizontal-large-3;

    @include responsive_mobile {
        order: 0;
        padding-left: 0;
        margin-bottom: $spacing_vertical-base;

        .image__item {
            max-height: 127px;
        }
    }
}

.row__floating-image--absolute {
    position: absolute;
    z-index: 1;
    left: 67%;
    top: 50%;
    transform: translateY(-50%);
    padding-left: initial;

    @include responsive_mobile {
        position: relative;
        left: initial;
        top: initial;
        transform: none;
        height: initial !important;
        margin-bottom: $spacing_vertical-base;
    }

    .image {
        height: 100%;

        @include responsive_mobile {
            position: relative;
        }
    }
    
    .image__item {
        max-width: initial;
        height: 100%;


        @include responsive_mobile {
            position: relative;
            max-height: 220px;
        }
    }
}