$navbar_height: 90px;
$navbar_height-mobile: 75px;
$navbar_background-color: $color_white;
$navbar_padding-vertical: 1rem;
$navbar_item-hover-color: $color_text-link-base;
$navbar_item-hover-background-color: $color_white;
$navbar_tab-active-border-bottom-width: 3px;

$t: 3s;
$d: 0.08em;
$n: 3;
$e: cubic-bezier(0.860, 0.000, 0.070, 1.000); 
$front: #B84446;
$back: #d6d6d6;

@mixin navbar-fixed {
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}


.navbar-wrapper {
  height: $navbar_height;
  @include responsive_mobile {
    height: $navbar_height-mobile;
  }
}

.navbar {
  @include navbar-fixed;

  z-index: 30;
  height: $navbar_height;
  padding-right: $spacing_horizontal-medium;
  padding-left: $spacing_horizontal-medium;
  background-color: $navbar_background-color;
  transform: translateY(0);
  transition: transform $easing_timing-fast-2 ease-in-out, box-shadow $easing_timing-fast ease-in-out, background-color $easing_timing-fast ease-in-out;

  @include responsive_mobile {
    height: $navbar_height-mobile;
    padding-right: $spacing_horizontal-base;
    padding-left: $spacing_horizontal-base;
    .a-button:hover,
    .a-button:focus,
    .a-button:active {
      transform: scale(1);
    }
  }
}

.navbar__container {
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  // max-width: $spacing_container-maxWidth;
  // margin-right: auto;
  // margin-left: auto;

  img {
    height: calc(#{$navbar_height-mobile} - 25px);         
  }
  .header-text-logo {
    color:#B84446;
    font-size: 1.5rem;
    font-weight: 600;
    @include responsive_mobile_only {
      font-size: 1.3rem;
    }
  }
  a:-webkit-any-link {
    text-decoration: none;
  }

  
}


@mixin nav-upper-level {
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  height: 100%;
}

@import 'mod/scrolling';

@import 'ele/brand';
@import 'ele/burger';
@import 'ele/content';
@import 'ele/end';
@import 'ele/item';
@import 'ele/menu';
