$font_size-small: 0.625rem;
$font_size-small-2: 0.75rem;
$font_size-small-3: 0.875rem;
$font_size-base: 1rem;
$font_size-medium: 1.125rem;
$font_size-medium-2: 1.25rem;
$font_size-medium-3: 1.75rem;
$font_size-large: 2rem;
$font_size-large-2: 3.06rem;
$font_size-large-3: 3.875rem;
$font_size-huge: 4.75rem;

$font_weight-normal: 400;
$font_weight-medium: 500;
$font_weight-semibold: 600;
$font_weight-bold: 700;

$font_family-base: 'Roboto', sans-serif;;
$font_family-accent: 'Roboto', sans-serif;;
$font_family-monospace: monospace;