$column-spacing: $spacing_horizontal-base;

.row__column {
  position: relative;
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: $column-spacing;

  @include responsive_mobile {
    flex: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: $spacing_vertical-small-2;
  }
}
.row__column__contact {
  width:365px;
}

.contact-wrapper {
  display: flex !important;
  justify-content:center;
  // width: 80%;
  margin-bottom: 1rem;

  @include responsive_mobile {
    justify-content: left;
  }

  @include responsive_tablet-only {
    justify-content: center;
  }

  .link {
    margin: 10px 0;
  }
  // .row__column__contact {
  //   @media screen and (max-width){
      
  //   }
  // }
}

.location-wrapper {
  justify-content: space-evenly;
  justify-content: center;
  // @include responsive_tablet-only {
  //   justify-content: left;
  //   width: 100%;
  // }
  // @include responsive_mobile {
  //   justify-content: left;
  //   width: 100%;
  // }
}

.mobile-divider {
  display: none;
  @include responsive_mobile {
    display: block;
    height: 1px;
    border: 0;
    border-top: 3px solid #f8f7f7;
    margin: 1em auto;
    padding: 0;
    width: 75%;
  }
}

.img-box {
  img {
    border-radius: 10px;
    margin-top: 25px;
    transition: 100ms ease-in-out;
  }
  img:hover {
    transition: transform 200ms ease-in-out;
    transform: scale(1.05);
  }
}
.img-box:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}