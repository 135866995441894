$spacing_vertical-base: 24px;
$spacing_vertical-small: 6px;
$spacing_vertical-small-2: 12px;
$spacing_vertical-medium: 24px * 1.75;
$spacing_vertical-medium-2: 24px * 2.5;
$spacing_vertical-large: 24px * 4;
$spacing_vertical-large-2: 24px * 6;

$spacing_horizontal-base: 14px;
$spacing_horizontal-small: 7px;
$spacing_horizontal-medium: 14px * 2;
$spacing_horizontal-medium-2: 14px * 3;
$spacing_horizontal-large: 14px * 4;
$spacing_horizontal-large-2: 14px * 6;
$spacing_horizontal-large-3: 14px * 7;

$spacing_container-maxWidth: 1400px;
$spacing_inner-container-maxWidth: 945px;