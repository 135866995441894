$icon_dimensions: 1.5rem;
$icon_dimensions-small: 1rem;
$icon_dimensions-medium: 2rem;
$icon_dimensions-large: 3rem;

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: $icon_dimensions;
  width: $icon_dimensions;

  img {
    height: 100%;
    max-width: 100%;
  }
}

@import 'mod-size';
