// TODO:
// The media queries: tablet starting at 769px and ending at 991px. If a smaller breakpoint is needed it'll probably be around 575px.
// @media all and (max-width: 1199px) { ... } 
// @media all and (max-width: 991px) { ... } 
// @media all and (max-width: 768px) { ... } 
// @media all and (max-width: 575px) { ... }

$responsive_breakpoint-mobile: 415px;
$responsive_breakpoint-tablet: 900px;
$responsive_breakpoint-desktop: 1124px;
$responsive_breakpoint-large-desktop: 1440px;

@mixin responsive_tablet-only {
  @media (min-width: $responsive_breakpoint-mobile) and (max-width : $responsive_breakpoint-desktop) {
    @content;
  }
}

@mixin responsive_mobile {
  @media (max-width : $responsive_breakpoint-tablet - 1px) {
    @content;
  }
}

@mixin responsive_mobile-only {
  @media (max-width : $responsive_breakpoint-mobile - 1px) {
    @content;
  }
}

@mixin responsive_mobile-portrait {
  @media screen and (orientation: portrait) and (max-width : $responsive_breakpoint-tablet - 1px) {
    @content;
  }
}

@mixin responsive_desktop-only {
  @media (min-width : $responsive_breakpoint-tablet) {
    @content;
  }
}

@mixin responsive_desktop-large-only {
  @media (min-width : $responsive_breakpoint-large-desktop) {
    @content;
  }
}
