.section {
  margin-bottom: $spacing_vertical-medium-2;
  padding-right: $spacing_horizontal-medium;
  padding-left: $spacing_horizontal-medium;
  position: relative;
  clear: both;
  overflow: hidden;

  @include responsive_mobile {
    margin-bottom: $spacing_vertical-medium;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .section {
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
  }
}

.section--no-vertical-spacing {
  margin-bottom: 0;
  .section__container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section--floating-above, .section--floating-above-quarter {
  z-index: 1;
  margin-bottom: 0;
  transform: translateY(50%);
  overflow: visible;

  @include responsive_mobile {
    transform: translateY(40%);
  }

  .section__container {
    padding-top: 0;
    padding-bottom: 0;
  }

  & + .section {
    .section__container {
      padding-top: 0;
    }

    .box--gray {
      padding-top: $spacing_vertical-large;
    }
  }
}

.section--floating-above {
  .box {
    padding-top: $spacing_vertical-medium;
    padding-bottom: $spacing_vertical-medium;

    .a-button {
      padding: $spacing_vertical-small-2 $spacing_horizontal-large;
    }
  }
}

.section--floating-above-quarter {
  transform: translateY($spacing_vertical-medium-2);

  @include responsive_mobile {
    transform: translateY($spacing_vertical-medium);
  }

  & + .section .box--gray {
    @include responsive_mobile {
      width: calc(100% + (#{$spacing_horizontal-medium} * 2)); 
      left: -$spacing_horizontal-medium;
    }
  }
}

.section--padded-vertical {
  .section__container {
    padding-top: $spacing_vertical-large;
    padding-bottom: $spacing_vertical-large;
    @include responsive_mobile {
      padding-bottom: $spacing_vertical-base;
    }
  }
}

.section--wrapper {
  .section__container {
    padding-top: 0;
  }
}

.section--visible-overflow {
  overflow: visible;
}
.section__flooring{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
  max-width: 1400px;
}
.flooring-section{
  display: flex;
  width: 100vw;
  justify-content: space-between;
  padding: 50px;
  img {
    height: 350px;
    width: 350px;
  }
  .flooring-details{
    display: flex;
    flex-direction: column;
    .heading{
      font: normal normal normal 38px/50px Roboto;
      letter-spacing: 0px;
      color: #0A0000;
      text-transform: capitalize;
      opacity: 1;
    }
    .desc{
      text-align: left;
      font: normal normal normal 16px/26px Roboto;
      letter-spacing: 0px;
      color: #120000;
      opacity: 1;
      padding: 10px;
    }

    width: 700px
  }
}
.reverse{
  background: #F6F6F6 0% 0% no-repeat padding-box;
  flex-direction: row-reverse;
}


@import 'mod-hero';
@import 'ele-container';

