@mixin heading {
    @include spacing_vertical-medium;
  
    word-break: break-word;
    font-family: $font_family-accent;
}

@mixin heading-small {
    @include spacing_vertical-small;
  
    word-break: break-word;
    font-family: $font_family-accent;
}
  