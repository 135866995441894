.a-button--text {
  color: $color_text-body-primary;
  text-decoration: underline;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $color_text-heading-base;
  }

  &[disabled] {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
  }
}
