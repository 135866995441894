.navbar__brand {
  @include nav-upper-level;

  .navbar__item {
    padding-left: 0;
  }

  .navbar__brand-logo {
    height: 100%;
    padding-top: $spacing_vertical-small-2;
    padding-bottom: 16px;
  }


  @include responsive_mobile {
    width: 100%;
  }
}
