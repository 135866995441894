.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;;
}
.sidenav_main_list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    display: flex !important;
    align-items: center;
  }
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
  color: #020000;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.25;

}
.sidenav a i {
  padding: 5px 10px 5px 5px;
}

.sidenav a:hover {
  color: #020000;
}

.sidenav .closebtn {
  font-size: 30px;
  margin-right: 16px;
  margin-top: 20px;
  text-align: right;
}

.products {
  padding-left: 45px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}