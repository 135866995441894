@mixin spacing_vertical {
  margin-bottom: $spacing_vertical-base;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin spacing_vertical-small {
  margin-bottom: $spacing_vertical-small;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin spacing_vertical-medium {
  margin-bottom: $spacing_vertical-medium;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin spacing_vertical-large {
  margin-bottom: $spacing_vertical-large;

  &:last-child {
    margin-bottom: 0;
  }
}
