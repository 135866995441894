.hide-mobile {
  @include responsive_mobile {
    display: none;
  }
}

.hide-mobile-tablet {
  @include responsive_mobile {
    display: none;
  }
  @include responsive_tablet-only {
    display: none;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blueColor {
  color: #0c69be !important;
}
.blueBgColor {
  background-color: #0c69be !important;
}

.px-m {
  padding: 0 $spacing_horizontal-medium;
}

.py-m {
  padding: $spacing_horizontal-medium 0;
}

.f-s-2 {
    font-size: $font_size-small-2 !important;
}

.f-s-3 {
    font-size: $font_size-small-3 !important;
}
