.navbar__item {
  text-transform: uppercase;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding: $spacing_vertical-base $spacing_horizontal-base;

  @include responsive_mobile {
    padding: 0;
  }

  .link {
    color: $color_black;
    font-weight: $font_weight-medium;

    &:hover {
      color: $color_primary-base;
    }
  }
}
.navbar__contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.navbar__item--end {
  padding-right: 0;
}

.navbar__item--mobile-cta {
  @include responsive_desktop-only {
    display: none;
  }

  z-index: 50;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid $color_gray-light;
  background-color: $color_white;

  .link {
    margin-top: $spacing_vertical-small-2;
    margin-bottom: $spacing_vertical-small-2;
    border-bottom: none !important;
    text-align: center;
    width: 100%;

    .a-button {
      width: 100%;
      margin-bottom: 0;
    }
  }
}