.title--size-7 {
  @include spacing_vertical-small;
  
  font-size: $font_size-base;
  line-height: 1.75;
  margin-bottom: 0;

  @include responsive_mobile {
    font-size: $font_size-base;
  }
}

.title--size-6 {
  @include spacing_vertical-small;

  font-size: $font_size-medium-2;

  @include responsive_mobile {
    font-size: $font_size-medium;
  }
}

.title--size-5 {
  font-size: $font_size-medium-2;

  @include responsive_mobile {
    font-size: $font_size-medium-2;
    margin-top: 20px;
  }
}

.title--size-4 {
  font-size: $font_size-large;

  @include responsive_mobile {
    font-size: $font_size-large;
  }
}
.title--size-4-mobile-width-fix {
  font-size: 1.5rem;
}

.title--size-3 {
  font-size: $font_size-large-2;
  letter-spacing: 0.5px;

  @include responsive_mobile {
    font-size: $font_size-large;
  }
}

.title--size-2 {
  font-size: $font_size-large-2;
  line-height: 1.13;

  @include responsive_tablet-only {
    font-size: 3.65rem;
  }

  @include responsive_mobile {
    font-size: 3rem;
  }
}

.title--size-1 {
  font-size: $font_size-huge;
  line-height: 1.08;

  @include responsive_tablet-only {
    font-size: 3.65rem;
  }

  @include responsive_mobile {
    font-size: 3rem;
  }
}
