.a-button--size-small {
  font-size: $font_size-small-2;
}

.a-button--size-medium {
  font-size: $font_size-medium;
}

.a-button--size-large {
  font-size: $font_size-medium;
}
