@mixin button_reset {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  
    &:hover {
      background: transparent;
    }
    &:active {
      // outline: none;
    }
    &:focus {
      // outline: 0;
    }
  }