.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  .tooltip-text {
    visibility: hidden;
    width: 400px;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
  }
  &:hover .tooltip-text {
    visibility: visible;
  }
}

.tooltip-title {
  margin-bottom: 20px;
  text-align: center;
  margin-top: -40px;
  font-size: 14px;
  padding: 0 28px;
  display: none;
  @include responsive_mobile {
    display: block;
  }
  @include responsive_tablet-only {
    display: block;
  }
}
