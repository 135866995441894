$subtitle_color-default: $color_black-2;

.subtitle {
  @include heading-small;

  color: $subtitle_color-default;
  font-weight: $font_weight-medium;
  font-size: $font_size-medium-3;
  letter-spacing: 0;
  line-height: 1.25;


}

@import 'mod-text-align';
@import 'mod-color';
@import 'mod-size';
