.loader-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  .loader {
    text-align: center;
    .spinner {
      width: 30px;
      height: 30px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      animation: loading-bar-spinner 600ms linear infinite;

      .spinner-icon {
        width: 30px;
        height: 30px;
        border: solid 3px transparent;
        border-top-color: #333 !important;
        border-left-color: #333 !important;
        border-radius: 50%;
      }
    }
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
