$generic_body-background-color: $color_white;
$generic_body-size: 16px;
$generic_body-size-mobile: 16px;
$generic_body-rendering: optimizeLegibility;
$generic_body-family: $font_family-base;
$generic_body-color: $color_text-body-base;
$generic_body-weight: $font_weight-normal;
$generic_body-line-height: 1.5;

$generic_code-family: $font_family-monospace;
$generic_code-padding: 0.25em 0.5em 0.25em;
$generic_code-weight: normal;
$generic_code-size: 0.875em;

$generic_strong-weight: $font_weight-bold;

html, body {
  height: 100%;
}

html {
  background-color: $generic_body-background-color;
  font-size: $generic_body-size;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: $generic_body-rendering;
  text-size-adjust: 100%;

  @include responsive_mobile {
    font-size: $generic_body-size-mobile;
  }
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $generic_body-family;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $generic_code-family;
}

body {
  color: $generic_body-color;
  font-size: 1rem;
  font-weight: $generic_body-weight;
  line-height: $generic_body-line-height;
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: inherit;
  font-weight: $generic_strong-weight;
}

pre {
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;
}

code {
  font-size: $generic_code-size;
  font-weight: $generic_code-weight;
  padding: $generic_code-padding;
}
